export function serializeForm(form) {
    /*
    Serialize a HTMLFormElement as a list of {name: <field name>, value: <field value>}
    disabled/reset/submit/buttonf fields are ignored
     */
    var serialized = [];
    // Loop through each field in the form
    for (var i = 0; i < form.elements.length; i++) {
        var field = form.elements[i];
        // Respect the same rules as jQuery's serializeArray
        if (!field.name || field.disabled || field.type === 'reset' || field.type === 'submit' || field.type === 'button') {
            continue;
        }
        if (field.type === "file") { // just returns the name of selected files
            for (var n = 0; n < field.files.length; n++) {
                serialized.push({ name: field.name, value: field.files[n].name });
            }
        }
        else if (field.type === 'select-multiple') { // get all selected options
            for (var n = 0; n < field.selectedOptions.length; n++) {
                serialized.push({ name: field.name, value: field.selectedOptions[n].value });
            }
        }
        else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
            serialized.push({ name: field.name, value: field.value });
        }
    }
    return serialized;
}
export function htmlFormsSet(opts) {
    function setFormFieldValue(form, name, value) {
        var item = form.elements.namedItem(name);
        if (item === null) {
            return;
        }
        else if (item instanceof RadioNodeList) {
            if ((value === true) || (value === false)) {
                item.forEach(function (input) {
                    input.checked = value;
                });
            }
            else if (Array.isArray(value)) {
                var valuesSet_1 = new Set(value);
                item.forEach(function (input) {
                    input.checked = valuesSet_1.has(input.value);
                });
            }
            else {
                item.forEach(function (input) {
                    input.checked = input.value === value;
                });
            }
        }
        else if (item instanceof HTMLTextAreaElement) {
            item.value = value;
        }
        else if ((item instanceof HTMLInputElement) && (item.type === "checkbox")) {
            if (value === true) {
                item.checked = true;
            }
            else if (value === false) {
                item.checked = false;
            }
            else {
                item.value = value;
            }
        }
        else if (item instanceof HTMLSelectElement) {
            var options = item.options;
            if (Array.isArray(value)) {
                var valuesSet = new Set(value);
                for (var i = 0; i < options.length; i++) {
                    options[i].selected = valuesSet.has(options[i].value);
                }
            }
            else {
                for (var i = 0; i < options.length; i++) {
                    options[i].selected = options[i].value === value;
                }
            }
        }
        else if (item instanceof HTMLInputElement) {
            item.value = value;
        }
    }
    document.querySelectorAll(opts.selector).forEach(function (form) {
        opts.values.forEach(function (values) {
            setFormFieldValue(form, values.name, values.value);
        });
    });
}
(function () {
    function websocketForms(evt) {
        /*
        search all HTML elements with an attribute "data-df-signal" that contains a JSON list of Signal objects:
        {
            name: "name of the signal to call",  (required)
            on: "name of the listened event",
            opts: "extra options",               (optional)
            form: "name of option to add to opts that contains the serialized form",  (optional)
            value: "name of option to add to opts that contains the value",  (optional)
            prevent: preventDefault               (optional, defaults to true for on=="submit" or "click" else false )
        }

        When the listened event is not given, the listened event is
            * "submit" for forms,
            * "click" for "reset"/"submit"/"button" input fields,
            * "change" for other fields.

        Using on a HTML form:
        ```html
        <form data-df-signal='[{"name": "signal.name", "on": "change", "form": "form_data", "opts": {"id": 42} }]'>
            <input type="text" name="title" value="df_websockets">
        </form>```
        or, using the Django templating system:
        ```html
        {% load df_websockets %}
        <form {% js_call "signal.name" on="change" form="form_data" id=42 %}>
            <input type="text" name="title" value="df_websockets">
        </form>```

        When the field "title" is modified, a signal "signal.name" is triggered on the server (via the websocket) with
        the following arguments:
        ```python
        form_data = [{"name": "title", "value": "df_websockets"}]
        id = 42
        ```

        Using on a HTML form input field:
        ```html
        <form>
            <input type="text" name="title" data-df-signal='[{"name": "signal.name", "on": "change", "value": "title", "opts": {"id": 42} }]'>
        </form>```
        or, using the Django templating system:
        ```html
        {% load df_websockets %}
        <form>
            <input type="text" name="title" {% js_call "signal.name" on="change" value="title" id=42 %}>
        </form>```

        When the field "title" is modified, a signal "signal.name" is triggered on the server (via the websocket) with
        the following arguments:
        ```python
        title = "new title value"
        id = 42
        ```
         */
        if (!evt.target.querySelectorAll) {
            return;
        }
        evt.target.querySelectorAll("[data-df-signal]").forEach(function (target) {
            var signals = JSON.parse(target.getAttribute("data-df-signal"));
            signals.forEach(function (signal) {
                var eventName = signal.on;
                if (!eventName) {
                    if (target.tagName === "FORM") {
                        eventName = "submit";
                    }
                    else if ((target.tagName === "INPUT" && !(target.type === 'reset' || target.type === 'submit' || target.type === 'button')) || (target.tagName === "TEXTAREA")) {
                        eventName = "change";
                    }
                    else {
                        eventName = "click";
                    }
                }
                var callback = function (evt) {
                    // noinspection JSUnresolvedVariable
                    var prevent = signal.prevent;
                    var opts = signal.opts || {};
                    if (signal.form) {
                        opts[signal.form] = serializeForm(target);
                    }
                    if (signal.value) {
                        if (target.type === "file") { // just returns the name of selected files
                            opts[signal.value] = [];
                            for (var n = 0; n < target.files.length; n++) {
                                opts[signal.value].push(target.files[n].name);
                            }
                        }
                        else if (target.type === 'select-multiple') { // get all selected options
                            opts[signal.value] = [];
                            for (var n = 0; n < target.selectedOptions.length; n++) {
                                opts[signal.value].push(target.selectedOptions[n].value);
                            }
                        }
                        else if (target.type === 'checkbox' || target.type === 'radio') {
                            opts[signal.value] = target.checked;
                        }
                        else {
                            opts[signal.value] = target.value;
                        }
                    }
                    window.DFSignals.call(signal.name, opts, undefined);
                    if (prevent === true || (prevent === null && eventName !== "change")) {
                        evt.preventDefault();
                    }
                };
                target.addEventListener(eventName, callback);
            });
        });
    }
    document.addEventListener("DOMContentAdded", function (evt) {
        window.setTimeout(function () {
            websocketForms(evt);
        }, 50);
        // awful trick for being sure that our addEventListener is the last.
        // allows things like CKEditor to push its content to the textarea before sending the content of the form.
    });
    document.addEventListener("DOMContentLoaded", function (evt) {
        window.setTimeout(function () {
            websocketForms(evt);
        }, 50);
        // awful trick for being sure that our addEventListener is the last.
        // allows things like CKEditor to push its content to the textarea before sending the content of the form.
    });
})();
